.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.navbar_header_margin {
  margin-left: 15% !important;
}
.content_margin_margin {
  margin-left: 10% !important;
  margin-top: 2% !important;
}
.bg-color-1{
  background-color: #ffbb33 !important;  
}
.frameclass{
  width:120px;
  height:240px;
}
.buttonclass{
  padding: 5px !important;
  width: 100px !important;
}
.menu_btn_size{
  font-size: 12px !important;
  border-radius: 20px ;
}
.maindiv_class{
  width: 100%;
}
.hide_sideNavBar{
  left: -650px;
  height: 100%;
  position:fixed;
  z-index: 999;
  background-color: #1c2331;
  transition: 1s;
  width: 0%;
}
.show_sideNavBar{
  left: 0px;
  height: 100%;
  width: 20%;
  position:fixed;
  z-index: 9999;
  background-color: #1c2331;
  transition: 1s;
}
.menu-list {
  color: #ffffff;
}
.menu-list .col-lg-12:hover{
    background-color: #ffffff;
    color: black;
    cursor: pointer;
}
.menu-list .container .col-lg-12:hover {
    background-color: #ffffff !important;
    color: black !important;
    cursor: pointer !important;
}
.moreclass {
  width: 160px;
  height: 280px;
  /* margin-top: 40%; */
}
.morebutton_class {
  border-radius: 25px;
  margin: 75% 0 0 0;
  padding: 10px !important;

}
.morebutton_class:active {
  border-radius: 25px;
  margin: 75% 0 0 0;
  padding: 10px !important;
}
.bg {
 /* background: url("/images/background_img.jpg") no-repeat;*/
  background-size: 100% 100%;
  background-image: linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12);;
  min-height: 100vh;
  bottom: 0;
  }
  .loader_class_on_main_page {
    width: 3rem;
    height: 3rem;
    margin-top: 20%;
  }
  .cardbackgroundclass {
    background-color: rgba(0,0,0,0.1);
  }
  .morebutton_class_new {
    height: 80px;
    width: 100px;
    font-size: 12px !important;
    color: #311b92;
  }
  .more_menu_btn_size{
    font-size: 12px !important;
    border-radius: 10px ;
  }
  @media screen and (max-width:768px) {
    .show_sideNavBar {
      width: 60%;
    }
    .hide_sideNavBar{
      left: -650px;
      width: 0%;
    }
  }
  .footerdiv_class{
    width: 100%;
  }
  .headerdiv_class{
    width: 100%;
  }
  .bg_footer {
    /* background: url("/images/dashboardTitle.jpg") no-repeat; */
    background-color:#e7e9ea !important;  
    bottom: 0;
  }
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
